<template>
    <div class="mealsPlan" :class="[!Dark ? '' : 'dark']">
        <div class="header">
            <div class="title">
                <img v-if="plan.kind == 1" src="@/assets/images/icons/u.png" alt />
                <img v-if="plan.kind == 2 && plan.type == 1" src="@/assets/images/icons/ci.png" alt />
                <img v-if="plan.kind == 2 && plan.type == 2" src="@/assets/images/icons/ku.png" alt />
                <img v-if="plan.kind == 3" src="@/assets/images/icons/kal.png" alt />
                {{plan.title + (DetailGetTemp || getData ? '' : '方案--' ) + timeText(plan.time_point)}}
            </div>

            <div class="nutrition">
                <span v-if="plan.kind == 1">碳水:{{Number( carbohydrateVal.toString().match(/^\d+(?:\.\d{0,2})?/) )}}g</span>
                <span v-if="plan.kind == 1">蛋白质:{{Number(proteinVal.toString().match(/^\d+(?:\.\d{0,2})?/))}}g</span>
                <span v-if="plan.kind == 1">脂肪:{{Number(fatVal.toString().match(/^\d+(?:\.\d{0,2})?/))}}g</span>
                <!-- v-if="id == uid" -->
                <i class="icon el-icon-edit-outline edit" @click="edit(plan,idx)" />
                <!-- v-if="id == uid" -->
                <i class="icon del el-icon-delete" @click="del(idx)" />
            </div>
        </div>
        <div class="foodList">
            <div class="foodItem" v-for="(item,index) in plan.details" :key="index">
                <el-tooltip class="tip" :content="item.title" placement="top-start"></el-tooltip>
                <p class="name">{{item.title}}</p>
                <p class="weight">{{ unitSelect(item.num,item.unit,item.unit_list,item.unit_id) }}</p>
                <!-- <p class="weight" v-if="getData && !DetailGetTemp">{{item.num}}{{item.unit}}</p>
                <p class="weight" v-else>{{Math.floor(item.num * 100) / 100}}{{unitSelect(item.unit_list,item.unit_id)}}{{unit}}</p> -->
            </div>
        </div>
        <div class="remark" v-if="plan.note">
            备注：<span>{{plan.note}}</span>
        </div>
    </div>
</template>

<script>

import { getItem } from '_js/Cache';
export default {
    props:{
        plan: Object,
        idx: Number,
        getData: Boolean,
        DetailGetTemp: Boolean,
        Dark: Boolean
    },
    watch:{
        plan(val){
            this.carbohydrateVal = 0;
            this.proteinVal = 0;
            this.fatVal = 0;
            this.countTotal(val)
        }
    },
    data() {
        return {
            carbohydrateVal: 0,
            proteinVal: 0,
            fatVal: 0,
            unit: '',
            id: '',
            uid: getItem('userInfo').id
        }
    },
    methods:{
        edit(plan,index){
            this.$emit('edit',plan,index)
        },
        del(index){
            let str = {
                index: index,
                id: this.plan.id,
                kind: this.plan.kind,
            }
            this.$confirm('确定删除？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                    customClass: !this.Dark ? '' : 'darkDetailsDialogBox'
            }).then( () =>{
                this.$emit('del', str)
            })
        },
        unitSelect(num,unit,list,id){
            let text = '',
                re = /^[\u4E00-\u9FA5]+$/;
            if(re.test(unit)){
                text = num + ' ' + unit
            }else{
                list.map( item =>{
                    if(item.id == id){
                        text = num + ' ' + item.title;
                    }
                })
            }
            return text;
            // list.map( item =>{
            //     if(item.id == id){
            //         this.unit = item.title;
            //     }
            // })
        },
        timeText(val){
            switch(parseInt(val)){
                case 1:
                    return this.DetailGetTemp || this.getData ? '' : '早餐前';
                case 2: 
                    return this.DetailGetTemp || this.getData ? '' : '早餐';
                case 3: 
                    return this.DetailGetTemp || this.getData ? '' : '早餐后';
                case 4: 
                    return this.DetailGetTemp || this.getData ? '' : '午餐前';
                case 5: 
                    return this.DetailGetTemp || this.getData ? '' : '午餐';
                case 6: 
                    return this.DetailGetTemp || this.getData ? '' : '午餐后';
                case 7: 
                    return this.DetailGetTemp || this.getData ? '' : '晚餐前';
                case 8: 
                    return this.DetailGetTemp || this.getData ? '' : '晚餐';
                case 9: 
                    return this.DetailGetTemp || this.getData ? '' : '晚餐后';
                case 10: 
                    return this.DetailGetTemp || this.getData ? '' : '睡前';
            }
        },
        countTotal(){
            let array = this.plan.details,
                defaultArr = this.plan.food_list;
            for(let i in array){
                // key,val,
                let carbohydrate,protein,fat,num;
                if(this.DetailGetTemp || this.getData){
                    if(defaultArr){
                        // key = Object.keys(JSON.parse(defaultArr[i].unit)),
                        // val = Object.values(JSON.parse(defaultArr[i].unit)),
                        carbohydrate = defaultArr[i].carbohydrate,
                        protein = defaultArr[i].protein,
                        fat = defaultArr[i].fat,
                        num = array[i].num;
                    }
                }else{
                    // key = Object.keys(JSON.parse(array[i].unit)),
                    // val = Object.values(JSON.parse(array[i].unit)),
                    carbohydrate = array[i].carbohydrate,
                    protein = array[i].protein,
                    fat = array[i].fat,
                    num = array[i].num;
                }
                //  * (key * val)
                this.carbohydrateVal =  this.carbohydrateVal + Number( carbohydrate * num / 100 );
                this.proteinVal = this.proteinVal + Number( protein * num / 100 );
                this.fatVal = this.fatVal + Number( fat * num / 100);
            }
        },
    },
    mounted(){
        this.id = this.plan.doctor_id;
        this.countTotal(this.plan.details);
    }
}
</script>

<style lang="less" scoped>
    .mealsPlan{
        box-sizing: border-box;
        padding: 15px 0 10px 10px;
        border-bottom: 1px solid #f4f4f4;

        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title{
                display: flex;
                align-items: center;
                height: 20px;
                font-size: 13px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #242424;

                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
            .nutrition{
                color: #9F9F9F;
                font-size: 12px;
                display: flex;
                align-items: center;

                span{
                    margin-left: 20px;
                }

                .icon{
                    font-size: 14px;
                    color: #666;
                    font-weight: bold;
                    margin-left: 15px;
                    cursor: pointer;
                }

                .del{
                    margin-left: 10px;
                }
            }
        }
        .foodList{
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;

            .foodItem{
                width: 130px;
                height: 44px;
                border-radius: 5px;
                border: 1px solid #E6E9F0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                flex-wrap: wrap;
                position: relative;
                margin:0 18px 17px 0;

                .name{
                    width: 80%;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #242424;
                }

                .weight{
                    width: 100%;
                    text-align: center;
                    color: #9F9F9F;
                }

                .tip{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }

            .foodItem:nth-child(6n){
                margin-right: 0;
            }
        }
        .remark{
            font-size: 12px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #242424;
            display: flex;
            align-items: center;

            span{
                color: #9F9F9F;
                font-weight: normal;
            }
        }
    }

    .mealsPlan.dark{
        border-color: #2c2c2e !important;
        .title{
            color: #c1c1c1 !important;
        }
        .nutrition{
            color: #c1c1c1;
            i{
                color: #c1c1c1 !important;
            }
        }
        .foodList{
            .foodItem{
                border-color: #2c2c2e !important;
            }
            p{
                color: #c1c1c1 !important;
            }
        }
    }

</style>


<style lang="less">

    .darkDetailsDialogBox{
        background-color: #0c0c0c;
        border-color: #0c0c0c;
        .el-message-box__title,
        .el-message-box__message{
            color: #c1c1c1;
        }
        .el-message-box__btns{
            .el-button:nth-child(1){
                background-color: #202025;
                border-color: #202025;
                color: #c1c1c1;
                &:hover{
                    color: var(--defalutColor) !important;
                }
            }
        }
    }
</style>